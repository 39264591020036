import {
  deepClone,
  getDSByName,
  overwriteObj,
  runDataSourceRequest,
  translateOptionItems,
  translateDataToTree,
} from "@/utils/util"
import FormValidators from "@/utils/validators"

export default {
  inject: [
    "refList",
    "getFormConfig",
    "globalOptionData",
    "globalModel",
    "getOptionData",
    "getGlobalDsv",
    "getReadMode",
  ],
  data() {
    return {
      fieldReadonlyFlag: false,
      timer: null,
      linkageMapping: [
        "input",
        "textarea",
        "number",
        "radio",
        "checkbox",
        "select",
        "multiselect",
        "time",
        "date",
        "switch",
        "user",
        "org",
      ],
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  computed: {
    formConfig() {
      return this.getFormConfig()
    },

    subFormName() {
      return this.parentWidget ? this.parentWidget.options.name : ""
    },

    subFormAllRowId() {
      const mapping = Object.keys(this.refList)
      const allRowId = [] // 所有子表单的行号
      mapping.map((name) => {
        const index = name.indexOf("@row")
        const rowId = index > -1 ? name.substring(index + 4, name.length) : false
        if (!allRowId.includes(rowId) && rowId) allRowId.push(rowId)
      })
      return allRowId
    },

    subFormItemFlag() {
      return this.parentWidget ? this.parentWidget.type === "sub-form" : false
    },

    formModel: {
      cache: false,
      get() {
        return this.globalModel.formModel
      },
    },

    isReadMode() {
      // return this.getReadMode() || this.fieldReadonlyFlag
      return this.getReadMode() ? true : this.fieldReadonlyFlag
    },

    optionLabel() {
      if (this.fieldModel === null) {
        return "--"
      } else {
        let resultContent = "--"
        this.field.options.optionItems.forEach((oItem) => {
          if (
            oItem.value.toString() === this.fieldModel ||
            this.findInArray(this.fieldModel, oItem.value.toString()) !== -1
          ) {
            resultContent = resultContent === "--" ? oItem.label : resultContent + " " + oItem.label
          }
        })

        return resultContent
      }
    },
    isMultiple() {
      return this.field.type === "multiselect" || this.field.type === "checkbox"
    },
  },

  methods: {
    optionLabels(optionItems) {
      if (this.fieldModel === null) {
        return "--"
      } else {
        let resultContent = "--"
        optionItems.forEach((oItem) => {
          if (oItem.id === this.fieldModel || this.findInArray(this.fieldModel, oItem.id) !== -1) {
            resultContent = resultContent === "--" ? oItem.name : resultContent + " " + oItem.name
          }
        })

        return resultContent
      }
    },
    findInArray(arrayObject, element) {
      if (!Array.isArray(arrayObject)) {
        return -1
      }

      let foundIdx = -1
      arrayObject.forEach((aItem, aIdx) => {
        if (aItem === element) {
          foundIdx = aIdx
        }
      })

      return foundIdx
    },

    // --------------------- 组件内部方法 begin ------------------//
    getPropName() {
      if (this.subFormItemFlag && !this.designState) {
        return this.subFormName + "." + this.subFormRowIndex + "." + this.field.options.name + ""
      } else {
        return this.field.options.name
      }
    },

    initFieldModel() {
      if (!this.field.formItemFlag) {
        return
      }

      if (!!this.subFormItemFlag && !this.designState) {
        // SubForm子表单组件需要特殊处理！！
        let subFormData = this.formModel[this.subFormName]
        if (
          (subFormData === undefined ||
            subFormData[this.subFormRowIndex] === undefined ||
            subFormData[this.subFormRowIndex][this.field.options.name] === undefined) &&
          this.field.options.defaultValue !== undefined
        ) {
          this.fieldModel = this.field.options.defaultValue
          subFormData[this.subFormRowIndex][this.field.options.name] = this.field.options.defaultValue
        } else if (subFormData[this.subFormRowIndex][this.field.options.name] === undefined) {
          this.fieldModel = null
          subFormData[this.subFormRowIndex][this.field.options.name] = null
        } else {
          this.fieldModel = subFormData[this.subFormRowIndex][this.field.options.name]
        }

        /* 主动触发子表单内field-widget的onChange事件！！ */
        setTimeout(() => {
          // 延时触发onChange事件, 便于更新计算字段！！
          this.handleOnChangeForSubForm(this.fieldModel, this.oldFieldValue, subFormData, this.subFormRowId)
        }, 800)
        this.oldFieldValue = deepClone(this.fieldModel)

        this.initFileList() // 处理图片上传、文件上传字段

        return
      }

      if (this.formModel[this.field.options.name] === undefined && this.field.options.defaultValue !== undefined) {
        this.fieldModel = this.field.options.defaultValue
      } else if (this.formModel[this.field.options.name] === undefined) {
        // 如果formModel为空对象，则初始化字段值为null!!
        this.formModel[this.field.options.name] = null
      } else {
        this.fieldModel = this.formModel[this.field.options.name]
      }
      this.oldFieldValue = deepClone(this.fieldModel)
      this.initFileList() // 处理图片上传、文件上传字段
    },

    initFileList() {
      // 初始化上传组件的已上传文件列表
      if ((this.field.type !== "picture-upload" && this.field.type !== "file-upload") || this.designState === true) {
        return
      }

      if (this.fieldModel) {
        if (Array.isArray(this.fieldModel)) {
          this.fileList = deepClone(this.fieldModel)
        } else {
          this.fileList.splice(0, 0, deepClone(this.fieldModel))
        }
      }
    },

    initEventHandler() {
      this.$on("setFormData", function (newFormData) {
        // console.log('formModel of globalModel----------', this.globalModel.formModel)
        if (!this.subFormItemFlag) {
          this.setValue(newFormData[this.field.options.name])
        }
        // 关联选项数据回显时不触发 change，在 setFormData 触发选项关联
        if (["radio", "checkbox", "select", "multiselect"].includes(this.field.type)) {
          this.handleAssociationOptions(true)
        }
      })

      this.$on("field-value-changed", function (values) {
        if (this.subFormItemFlag) {
          let subFormData = this.formModel[this.subFormName]
          this.handleOnChangeForSubForm(values[0], values[1], subFormData, this.subFormRowId)
        } else {
          this.handleOnChange(values[0], values[1])
        }
      })

      /* 监听重新加载选项事件 */
      this.$on("reloadOptionItems", function (widgetNames) {
        if (widgetNames.length === 0 || widgetNames.indexOf(this.field.options.name) > -1) {
          this.initOptionItems(true)
        }
      })
    },

    handleOnCreated() {
      if (this.field.options.onCreated) {
        let customFunc = new Function(this.field.options.onCreated)
        customFunc.call(this)
      }
    },

    handleOnMounted() {
      if (this.field.options.onMounted) {
        let mountFunc = new Function(this.field.options.onMounted)
        mountFunc.call(this)
      }
      // 表单禁用时不加载公式
      // this.formulaCal("mounted")
      if (!this.formConfig.disabled) this.formulaCal("mounted")
      this.handleAssociationOptions()
      if (this.linkageMapping.includes(this.field.type)) this.handleDataLinkage()
      if (this.field.type === "user") {
        if (this.field.options.default2LoginUser) {
          const v = this.field.options.multiple ? [abp.session?.userId] : abp.session?.userId
          this.setValue(v)
          this.field.options.defaultValue = v
          this.field.options.valueLabel = [{ value: abp.session?.userId.toString(), label: abp.session?.user?.name }]
        }
      }
    },

    registerToRefList(oldRefName) {
      if (this.refList !== null && !!this.field.options.name) {
        if (this.subFormItemFlag && !this.designState) {
          // 处理子表单元素（且非设计状态）
          if (oldRefName) {
            delete this.refList[oldRefName + "@row" + this.subFormRowId]
          }
          this.refList[this.field.options.name + "@row" + this.subFormRowId] = this
        } else {
          if (oldRefName) {
            delete this.refList[oldRefName]
          }
          this.refList[this.field.options.name] = this
        }
      }
    },

    unregisterFromRefList() {
      // 销毁组件时注销组件ref
      if (this.refList !== null && !!this.field?.options.name) {
        let oldRefName = this.field.options.name
        if (this.subFormItemFlag && !this.designState) {
          // 处理子表单元素（且非设计状态）
          delete this.refList[oldRefName + "@row" + this.subFormRowId]
        } else {
          delete this.refList[oldRefName]
        }
      }
    },

    initOptionItems(keepSelected) {
      if (this.designState) {
        // 添加自定义组件时把他的数据源也一起添加进去
        if (["mould", "process", "customer", "supplier", "machining", "stock"].includes(this.field.as)) {
          const names = this.formConfig.dataSources.map((ds) => ds.uniqueName)
          if (!names.includes(this.field.options.dsName)) {
            const dsRemotes = this.$pinia.state.value.dataSource.dsRemotes
            const dsRemoteItem = dsRemotes.find((item) => item.name === this.field.options.dsName)
            if (dsRemoteItem) {
              dsRemoteItem.setting.id = dsRemoteItem.id
              const dsItem = { ...dsRemoteItem.setting }
              this.formConfig.dataSources.push(dsItem)
            }
          }
        }
        return
      }

      if (["radio", "checkbox", "select", "multiselect", "cascader"].includes(this.field.type)) {
        /* 首先处理数据源选项加载 */

        if (this.field.options.optionType) {
          if (this.field.options.dsParentWidget) {
            this.getTriggerOptionItems()
          }
          if (this.isReadMode) return
          if (this.field.options.optionType === 1) {
            this.useDataSourceForOptionItems()
            return
          } else if (this.field.options.optionType === 2) {
            // MLMW关联其他表单数据第二步
            // 关联其他表单数据选项加载
            this.useFormDataForOptionItems()
            return
          }
        }

        /* 异步更新option-data之后globalOptionData不能获取到最新值，改用provide的getOptionData()方法 */
        const newOptionItems = this.getOptionData()
        if (!!newOptionItems && Object.prototype.hasOwnProperty.call(newOptionItems, this.field.options.name)) {
          if (keepSelected) {
            this.reloadOptions(newOptionItems[this.field.options.name])
          } else {
            this.loadOptions(newOptionItems[this.field.options.name])
          }
        }
      }
    },

    useDataSourceForOptionItems(keyword) {
      this.field.options.optionItems.splice(0, this.field.options.optionItems.length) // 清空原有选项
      const curDS = getDSByName(this.formConfig, this.field.options.dsName)
      if (curDS) {
        const gDsv = this.getGlobalDsv() || {}
        // console.log('Global DSV is: ', gDsv)
        const localDsv = new Object({})
        overwriteObj(localDsv, gDsv)
        localDsv.widgetName = this.field.options.name
        const fieldIndex = curDS.params.findIndex((item) => item.name === "keyword")
        if (fieldIndex > -1) {
          if (keyword) {
            curDS.params[fieldIndex].value = keyword
          } else {
            curDS.params.splice(fieldIndex, 1)
          }
        } else if (keyword) {
          curDS.params.push({
            name: "keyword",
            type: "String",
            value: keyword,
          })
        }
        const parentWidgetKey = this.field.options.parentWidgetKey
        const pidIndex = curDS.params.findIndex((item) => item.name === "oaParams")
        if (pidIndex > -1) {
          if (this.field.options.dsParentWidget) {
            const { dsParentWidget } = this.getParentWidgetValue(this.field.options.dsParentWidget)
            curDS.params[pidIndex].value = JSON.stringify({ [parentWidgetKey]: dsParentWidget.getValue() })
          } else {
            curDS.params.splice(pidIndex, 1)
          }
        } else if (this.field.options.dsParentWidget) {
          const { dsParentWidget } = this.getParentWidgetValue(this.field.options.dsParentWidget)
          curDS.params.push({
            name: "oaParams",
            type: "String",
            value: JSON.stringify({ [parentWidgetKey]: dsParentWidget.getValue() }),
          })
        }

        runDataSourceRequest(curDS, localDsv, false, this.$message)
          .then((res) => {
            if (this.field.type === "cascader") res = translateDataToTree(res)
            if (this.field.options.valueLabel.length && this.field.options.defaultValue) {
              const ids = res.map((item) => item.value)
              this.field.options.valueLabel.map((item) => {
                item.value = +item.value
                if (!ids.includes(item.value)) res.push(item)
              })
              this.setValue(this.field.options.defaultValue.toString())
              this.field.options.defaultValue = this.field.options.defaultValue.toString()
            }
            this.loadOptions(res)
          })
          .catch((err) => {
            this.$message.error(err.message)
          })
      }
    },

    useFormDataForOptionItems(keyword) {
      this.field.options.optionItems.splice(0, this.field.options.optionItems.length) // 清空原有选项
      // const optionAppId = this.field.options.optionAppId // 指定应用
      const optionFormId = this.field.options.optionFormId // 指定表单
      if (!optionFormId) return this.$message.warning(`${this.field.options.label}选项未选择指定表单，请前往表单编辑`)
      const params = {
        dataSetOrPageId: optionFormId,
        page: 1,
        limit: 50,
      }
      if (keyword) {
        params.keyword = keyword
      } else {
        delete params.keyword
      }
      const parentWidgetKey = this.field.options.parentWidgetKey
      if (this.field.options.dsParentWidget) {
        const { dsParentWidget } = this.getParentWidgetValue(this.field.options.dsParentWidget)
        if (dsParentWidget) params.oaParams = JSON.stringify({ [parentWidgetKey]: dsParentWidget.getValue() })
      } else {
        delete params.oaParams
      }
      this.$oaAjax
        .post(`/api/oa/dataset/fetchdatapageresult`, params)
        .then((res) => {
          const result = this.handleFormData(res)
          if (this.field.type === "cascader") result.data = translateDataToTree(result.data)
          if (this.field.options.valueLabel.length && this.field.options.defaultValue) {
            const ids = result.data.map((item) => item[this.field.options.valueKey])
            this.field.options.valueLabel.map((item) => {
              if (!ids.includes(item.value)) result.data.push(item)
            })
          }
          this.loadOptions(result.data)
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },

    // 获取指定子表单组件对应的子表单的所有行号
    getSubformAllRowId(widgetName) {
      const mapping = Object.keys(this.refList)
      const allRowId = [] // 所有子表单的行号
      mapping.map((name) => {
        const index = name.indexOf(widgetName + "@row")
        const rowId = index > -1 ? name.substring(index + widgetName.length + 4, name.length) : false
        if (!allRowId.includes(rowId) && rowId) allRowId.push(rowId)
      })
      return allRowId
    },

    getParentWidgetValue(id) {
      let dsParentWidget = this.getWidgetRef(id)
      let bindingWidgetIsSubform = false
      if (!dsParentWidget) {
        const subformAllRowId = this.getSubformAllRowId(id)
        const realWidgetName = id + "@row" + (this.subFormRowId || subformAllRowId[0])
        dsParentWidget = this.getWidgetRef(realWidgetName)
        bindingWidgetIsSubform = true
      }
      return {
        dsParentWidget: dsParentWidget,
        bindingWidgetIsSubform: bindingWidgetIsSubform,
      }
    },

    // 所依赖的父组件 获取 option 数据所需调用接口的父组件名称
    getTriggerOptionItems() {
      if (!this.formConfig.triggerOptionItems) this.formConfig.triggerOptionItems = []
      const { name, dsParentWidget } = this.field.options
      const id = `${dsParentWidget}_${name}`
      const ids = this.formConfig.triggerOptionItems.map((item) => `${item.id}_${item.cid}`)
      if (!ids.includes(id)) {
        this.formConfig.triggerOptionItems.push({
          id: dsParentWidget,
          cid: name,
        })
      }
    },

    // 所依赖的父组件 触发父组件时子组件的 options 值重新调用接口
    triggerDsOptionItems() {
      const changeWidget = this.getParentWidgetValue(this.field.options.name)
      if (!changeWidget.dsParentWidget) return
      this.formConfig.triggerOptionItems.map((item) => {
        if (this.field.options.name === item.id) {
          const { dsParentWidget, bindingWidgetIsSubform } = this.getParentWidgetValue(item.cid)
          if (dsParentWidget) {
            if (dsParentWidget.field.options.optionType === 1) {
              this.useCDSForOptionItems(dsParentWidget, changeWidget.dsParentWidget.getValue(), bindingWidgetIsSubform)
            } else if (dsParentWidget.field.options.optionType === 2) {
              this.useCFDForOptionItems(dsParentWidget, changeWidget.dsParentWidget.getValue(), bindingWidgetIsSubform)
            }
          }
        }
      })
    },

    // 所依赖的父组件 数据源请求
    useCDSForOptionItems(w, val, bindingWidgetIsSubform) {
      w.field.options.optionItems.splice(0, w.field.options.optionItems.length) // 清空原有选项
      const curDS = getDSByName(this.formConfig, w.field.options.dsName)
      if (curDS) {
        const gDsv = this.getGlobalDsv() || {}
        // console.log('Global DSV is: ', gDsv)
        const localDsv = new Object({})
        overwriteObj(localDsv, gDsv)
        localDsv.widgetName = w.field.options.name
        const parentWidgetKey = w.field.options.parentWidgetKey
        const pidIndex = curDS.params.findIndex((item) => item.name === "oaParams")
        if (pidIndex > -1) {
          if (val) {
            curDS.params[pidIndex].value = JSON.stringify({ [parentWidgetKey]: val })
          } else {
            curDS.params.splice(pidIndex, 1)
          }
        } else if (val) {
          curDS.params.push({
            name: "oaParams",
            type: "String",
            value: JSON.stringify({ [parentWidgetKey]: val }),
          })
        }
        runDataSourceRequest(curDS, localDsv, false, this.$message)
          .then((res) => {
            if (this.field.type === "cascader") res = translateDataToTree(res)
            if (bindingWidgetIsSubform && !this.subFormItemFlag) {
              const allSubformOptionItems = translateOptionItems(
                res,
                w.field.type,
                w.field.options.labelKey,
                w.field.options.valueKey,
                w.field.options.auxiliaryTextKey
              )
              const subformAllRowId = this.getSubformAllRowId(w.field.options.name)
              subformAllRowId.map((subformRowId) => {
                const realWidgetName = w.field.options.name + "@row" + subformRowId
                const dsParentWidget = this.getWidgetRef(realWidgetName)
                dsParentWidget.field.options.optionItems = allSubformOptionItems
              })
            } else {
              w.field.options.optionItems = translateOptionItems(
                res,
                w.field.type,
                w.field.options.labelKey,
                w.field.options.valueKey,
                w.field.options.auxiliaryTextKey
              )
            }
          })
          .catch((err) => {
            this.$message.error(err.message)
          })
      }
    },

    // 所依赖的父组件 表单数据请求
    useCFDForOptionItems(w, val, bindingWidgetIsSubform) {
      w.field.options.optionItems.splice(0, w.field.options.optionItems.length) // 清空原有选项
      // const optionAppId = this.field.options.optionAppId // 指定应用
      const optionFormId = w.field.options.optionFormId // 指定表单
      if (!optionFormId) return this.$message.warning(`${w.field.options.label}选项未选择指定表单，请前往表单编辑`)
      const params = {
        dataSetOrPageId: optionFormId,
        page: 1,
        limit: 50,
      }
      const parentWidgetKey = w.field.options.parentWidgetKey
      if (val) {
        params.oaParams = JSON.stringify({ [parentWidgetKey]: val })
      } else {
        delete params.oaParams
      }
      this.$oaAjax
        .post(`/api/oa/dataset/fetchdatapageresult`, params)
        .then((res) => {
          const result = this.handleFormData(res)
          if (this.field.type === "cascader") result.data = translateDataToTree(result.data)
          if (bindingWidgetIsSubform && !this.subFormItemFlag) {
            const allSubformOptionItems = translateOptionItems(
              result.data,
              w.field.type,
              w.field.options.labelKey,
              w.field.options.valueKey,
              w.field.options.auxiliaryTextKey
            )
            const subformAllRowId = this.getSubformAllRowId(w.field.options.name)
            subformAllRowId.map((subformRowId) => {
              const realWidgetName = w.field.options.name + "@row" + subformRowId
              const dsParentWidget = this.getWidgetRef(realWidgetName)
              dsParentWidget.field.options.optionItems = allSubformOptionItems
            })
          } else {
            w.field.options.optionItems = translateOptionItems(
              result.data,
              w.field.type,
              w.field.options.labelKey,
              w.field.options.valueKey,
              w.field.options.auxiliaryTextKey
            )
          }
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },

    handleFormData(res) {
      res.rawData = [...res.data]
      res.data.map((item, i) => {
        const obj = JSON.parse(item)
        Object.keys(obj).map((field) => {
          obj[field] = obj[field].value || obj[field].$oid
        })
        res.data[i] = obj
      })
      return res
    },

    refreshDefaultValue() {
      if (this.designState === true && this.field.options.defaultValue !== undefined) {
        this.fieldModel = this.field.options.defaultValue
        const selectedItem = []
        this.field.options.optionItems.map((item) => {
          if (Array.isArray(this.field.options.defaultValue)) {
            if (this.field.options.defaultValue.includes(item.value.toString())) selectedItem.push(item)
          } else {
            if (item.value.toString() === this.field.options.defaultValue) selectedItem.push(item)
          }
        })
        this.field.options.valueLabel = selectedItem
      }
    },

    clearFieldRules() {
      if (!this.field.formItemFlag) {
        return
      }

      this.rules.splice(0, this.rules.length) // 清空已有
    },

    buildFieldRules() {
      if (!this.field.formItemFlag) {
        return
      }

      this.rules.splice(0, this.rules.length) // 清空已有
      if (this.field.options.required) {
        this.rules.push({
          required: true,
          // trigger: ['blur', 'change'],
          trigger: [
            "blur",
          ] /* 去掉change事件触发校验，change事件触发时formModel数据尚未更新，导致radio/checkbox必填校验出错！！ */,
          message: this.field.options.requiredHint || this.i18nt("render.hint.fieldRequired"),
        })
      }

      if (this.field.options.validation) {
        let vldName = this.field.options.validation
        if (FormValidators[vldName]) {
          this.rules.push({
            validator: FormValidators[vldName],
            trigger: ["blur", "change"],
            label: this.field.options.label,
            errorMsg: this.field.options.validationHint,
          })
        } else {
          this.rules.push({
            validator: FormValidators.regExp,
            trigger: ["blur", "change"],
            regExp: vldName,
            label: this.field.options.label,
            errorMsg: this.field.options.validationHint,
          })
        }
      }

      if (this.field.options.onValidate) {
        // let customFn = new Function('rule', 'value', 'callback', this.field.options.onValidate)
        let customFn = (rule, value, callback) => {
          let tmpFunc = new Function("rule", "value", "callback", this.field.options.onValidate)
          return tmpFunc.call(this, rule, value, callback)
        }
        this.rules.push({
          validator: customFn,
          trigger: ["blur", "change"],
          label: this.field.options.label,
        })
      }
    },

    /**
     * 禁用字段值变动触发表单校验
     */
    disableChangeValidate() {
      if (!this.rules) {
        return
      }

      this.rules.forEach((rule) => {
        if (rule.trigger) {
          rule.trigger.splice(0, rule.trigger.length)
        }
      })
    },

    /**
     * 启用字段值变动触发表单校验
     */
    enableChangeValidate() {
      if (!this.rules) {
        return
      }

      this.rules.forEach((rule) => {
        if (rule.trigger) {
          rule.trigger.push("blur")
          rule.trigger.push("change")
        }
      })
    },

    disableOptionOfList(optionList, optionValue) {
      if (!!optionList && optionList.length > 0) {
        optionList.forEach((opt) => {
          if (opt.value === optionValue) {
            opt.disabled = true
          }
        })
      }
    },

    enableOptionOfList(optionList, optionValue) {
      if (!!optionList && optionList.length > 0) {
        optionList.forEach((opt) => {
          if (opt.value === optionValue) {
            opt.disabled = false
          }
        })
      }
    },

    // --------------------- 组件内部方法 end ------------------//

    // --------------------- 事件处理 begin ------------------//

    emitFieldDataChange(newValue, oldValue) {
      this.$emit("field-value-changed", [newValue, oldValue])

      /* 必须用dispatch向指定父组件派发消息！！ */
      this.dispatch("VFormRender", "fieldChange", [
        this.field.options.name,
        newValue,
        oldValue,
        this.subFormName,
        this.subFormRowIndex,
      ])
    },

    syncUpdateFormModel(value) {
      if (this.designState) {
        return
      }

      if (this.subFormItemFlag) {
        let subFormData = this.formModel[this.subFormName] || [{}]
        let subFormDataRow = subFormData[this.subFormRowIndex]
        subFormDataRow[this.field.options.name] = value
      } else {
        this.formModel[this.field.options.name] = value
      }
    },

    handleChangeEvent(value, options) {
      /* input的清除输入小按钮会同时触发handleChangeEvent、handleInputCustomEvent！！ */
      this.syncUpdateFormModel(value)
      this.emitFieldDataChange(value, this.oldFieldValue)

      // number组件一般不会触发focus事件，故此处需要手工赋值oldFieldValue！！
      this.oldFieldValue = deepClone(value) /* oldFieldValue需要在initFieldModel()方法中赋初值!! */

      // 数据源依赖于父级组件
      const dsIds = this.formConfig.triggerOptionItems?.map((d) => d.id)
      if (dsIds?.includes(this.field.options.name)) this.triggerDsOptionItems()

      /* 主动触发表单的单个字段校验，用于清除字段可能存在的校验错误提示 */
      this.dispatch("VFormRender", "fieldValidation", [this.getPropName()])
      this.formulaCal("change")
      if (["radio", "checkbox", "select", "multiselect"].includes(this.field.type)) this.handleAssociationOptions(true)
      if (this.linkageMapping.includes(this.field.type)) this.handleDataLinkage()
      const flag = ["radio", "checkbox", "select", "multiselect", "user", "org", "cascader"].includes(this.field.type)
      if (flag && !value) {
        const subFormData = this.formModel[this.subFormName]
        if (subFormData?.length && Object.keys(subFormData[0]).includes(this.field.options.name)) {
          const realWidgetName = this.field.options.name + "@row" + this.subFormRowId
          const subWidgetName = this.getWidgetRef(realWidgetName)
          subWidgetName.setWidgetOption("valueLabel", [])
        } else {
          this.field.options.valueLabel = []
        }
      }
      // 存选中的对象数组到 valueLabel 中， 存取数据用
      if (flag && value) {
        const res = []
        const optionItems = options || this.field.options.optionItems
        if (this.field.type === "cascader") {
          if (this.field.options.multiple) {
            value.map((vs) => {
              this.handleCascaderOption(res, deepClone(optionItems), vs)
            })
          } else {
            this.handleCascaderOption(res, deepClone(optionItems), value)
          }
        } else {
          optionItems.map((item) => {
            if (
              value
                .toString()
                .split(",")
                .includes(item[options ? "id" : "value"].toString())
            ) {
              res.push({
                value: options ? item.id.toString() : item.value.toString(),
                label: options ? item.name : item.label,
              })
            }
          })
        }
        const subFormData = this.formModel[this.subFormName]
        if (subFormData?.length && Object.keys(subFormData[0]).includes(this.field.options.name)) {
          const realWidgetName = this.field.options.name + "@row" + this.subFormRowId
          const subWidgetName = this.getWidgetRef(realWidgetName)
          subWidgetName.setWidgetOption("valueLabel", res)
        } else {
          this.field.options.valueLabel = res
        }
      }
    },

    handleCascaderOption(res, optionItems, value, label) {
      optionItems.map((item) => {
        if (value.toString().split(",").includes(item.value.toString())) {
          item.label = label ? `${label} - ${item.label}` : item.label
          if (item.children) this.handleCascaderOption(res, item.children, value, item.label)
          res.splice(0, 0, {
            value: item.value.toString(),
            label: item.label,
          })
        }
      })
    },

    handleFocusCustomEvent(event) {
      this.oldFieldValue = deepClone(this.fieldModel) // 保存修改change之前的值

      if (this.field.options.onFocus) {
        let customFn = new Function("event", this.field.options.onFocus)
        customFn.call(this, event)
      }
      // if (this.field.options.remote && this.field.options.optionType) {
      //   // 未编写远程搜索函数，但是开启了远程搜索
      //   if (this.field.options.optionType === 1) {
      //     this.useDataSourceForOptionItems("")
      //   } else {
      //     this.useFormDataForOptionItems("")
      //   }
      // }
    },

    handleBlurCustomEvent(event) {
      if (this.field.options.onBlur) {
        let customFn = new Function("event", this.field.options.onBlur)
        customFn.call(this, event)
      }
    },

    handleInputCustomEvent(value) {
      this.syncUpdateFormModel(value)

      /* 主动触发表单的单个字段校验，用于清除字段可能存在的校验错误提示 */
      this.dispatch("VFormRender", "fieldValidation", [this.getPropName()])

      if (this.field.options.onInput) {
        let customFn = new Function("value", this.field.options.onInput)
        customFn.call(this, value)
      }
      this.formulaCal("input")
    },

    // 公式第六步 触发公式所涉及的组件
    /**
     * @description: 公式的触发
     * @param {*} type 当前触发方式 mounted input change
     */
    formulaCal(type) {
      const formulaFields = this.field.options.formulaFields
      if (!formulaFields?.length) return
      formulaFields.some((formulaField) => {
        // subFormItemFlag = true 当前激活的为子表单
        const resWidget = !this.subFormItemFlag
          ? this.getWidgetRef(formulaField)
          : this.refList[`${formulaField}@row${this.subFormRowId}`] || this.getWidgetRef(formulaField)
        if (!resWidget) {
          // 子表单所有行触发公式
          const mapping = Object.keys(this.refList)
          if (mapping.join().includes(formulaField)) {
            this.subFormAllRowId.map((rowId) => {
              this.handleFormula(this.refList[`${formulaField}@row${rowId}`], formulaField, type, rowId)
            })
          }
          return true // 结果组件被删除处理
        }
        this.handleFormula(resWidget, formulaField, type)
      })
    },

    handleFormula(resWidget, formulaField, type, rowId) {
      if (resWidget?.field.options.valueType !== 1) return true // 当前默认值类型不为公式处理
      if (this.field.options.name === formulaField) {
        // 当结果组件公式不涉及其他组件触发时，直接在 mounted 阶段执行，其他时候不执行
        if (type === "mounted") resWidget.setValue(eval(this.field.options.formula))
      } else {
        const formula = resWidget.field.options.formula // 拿到结果组件中的公式
        let resFormula = formula
        let temp
        const reg = /\[(.*?)\]/g
        while ((temp = reg.exec(formula))) {
          const widget = this.getWidgetRef(temp[1]) || this.refList[`${temp[1]}@row${this.subFormRowId || rowId}`] // 拿到公式涉及的组件
          let value = widget?.getValue() || 0
          if (formula.includes("SUM")) {
            if (
              widget?.parentWidget?.type === "sub-form" &&
              (!resWidget?.parentWidget || resWidget?.parentWidget?.type !== "sub-form")
            ) {
              const subFormData = this.formModel[widget.parentWidget.id]
              const values = []
              subFormData?.map((subFormRow) => {
                values.push(+subFormRow[temp[1]] || 0)
              })
              value = values.length ? values.join("+") : value
            }
            resFormula = resFormula.replaceAll(",", "+")
            resFormula = resFormula.replaceAll("，", "+")
            resFormula = resFormula.replaceAll("SUM", "")
          }
          resFormula = resFormula.replaceAll(temp[0], value) // 替换掉公式的内容
        }
        resWidget.setValue(eval(resFormula)) // 结果组件
        resWidget.handleChangeEvent(eval(resFormula)) // 联动触发
        if (resWidget.field.options.uppercase) resWidget.setValue(this.amountLtoU(eval(resFormula))) // 结果组件
      }
    },

    /**
     * @description: 小写金额转化大写金额
     * @param {*} amount 小写金额
     * @return {*} 大写金额
     */
    amountLtoU(amount) {
      if (isNaN(amount) || amount >= 1000000000000) return "无效金额" // 数值最大不超过1万亿
      const sPrefix = amount < 0 ? "(负)" : "" // 将负号‘-’显示成汉字‘(负)’
      let sAmount = Math.abs(amount).toFixed(2) // 格式化成两位小数格式（两位小数对应“'角”和“分”）
      let sUnit = "仟佰拾亿仟佰拾万仟佰拾元角分" // 14个权位单位
      const sCapital = "零壹贰叁肆伍陆柒捌玖" // 10个大写数字
      sAmount = sAmount.replace(".", "") // 去除小数点（使数字与权位对应）
      sUnit = sUnit.substr(sUnit.length - sAmount.length) // 截取权位
      let sOutput = ""
      for (let i = 0, len = sAmount.length; i < len; i++) {
        // 拼接数字与权位单位
        sOutput += sCapital.substr(sAmount.substr(i, 1), 1) + sUnit.substr(i, 1)
      }
      return (
        sPrefix +
        sOutput
          .replace(/零角零分$/, "整")
          .replace(/零[仟佰拾]/g, "零")
          .replace(/零{2,}/g, "零")
          .replace(/零([亿|万])/g, "$1")
          .replace(/零+元/, "元")
          .replace(/亿零{0,3}万/, "亿")
          .replace(/^元/, "零元")
      )
    },

    // 关联选项第五步
    /**
     * @description: 关联组件触发
     * @param {*} flag true = change, false = mounted
     */
    handleAssociationOptions(flag) {
      if (!flag) {
        // mounted
        const hiddenRulesWidget = this.field.options.hiddenRulesWidget
        if (!hiddenRulesWidget?.length) return
        const currentWidget = this.getWidgetRef(this.field.options.name)
        currentWidget.setHidden(true) // 当前组件有隐藏规则则直接隐藏，当条件满足时再显示
        // mounted 加载数据有默认值时
        hiddenRulesWidget.some((widgetName) => {
          const ruleWidget = this.getWidgetRef(widgetName) // 获取规则组件
          const optionItems = ruleWidget.field.options.optionItems
          let flag = false
          optionItems.map((option) => {
            if (option.widgetNames?.includes(this.field.options.name)) {
              flag = true
            }
          })
          if (!flag) {
            // 关联选项已被取消选择处理
            currentWidget.setWidgetOption("hiddenRulesWidget", [])
          }
          if (!ruleWidget || ruleWidget.field.options.optionType || !flag) {
            currentWidget.setHidden(false)
            return true // 组件被删除或类型选项类型不等于自定义处理
          }
          const currentValue = ruleWidget.getValue()
          const hiddenRulesMultiple = ruleWidget.field.options.hiddenRulesMultiple
          if (hiddenRulesMultiple?.length) {
            hiddenRulesMultiple.some((item) => {
              if (item.widgetNames?.includes(this.field.options.name)) {
                // 多选组件判断显隐处理，先排序在序列号比对
                const flag = item.optionValues.sort().join() === currentValue.sort().join()
                currentWidget.setHidden(!flag) // 显示当前组件
                return true
              }
            })
          } else {
            optionItems.some((option) => {
              if (option.widgetNames?.includes(this.field.options.name)) {
                if (currentValue === option.value.toString()) {
                  currentWidget.setHidden(false) // 显示当前组件
                  return true
                }
              }
            })
          }
        })
      } else {
        // change
        if (this.field.options.optionType) return
        const currentWidget = this.getWidgetRef(this.field.options.name)
        if (!currentWidget) return
        const currentValue = currentWidget.getValue()
        if (this.isMultiple) {
          const hiddenRulesMultiple = this.field.options.hiddenRulesMultiple
          hiddenRulesMultiple?.map((item) => {
            item.widgetNames.map((widgetName) => {
              const resWidget = this.getWidgetRef(widgetName)
              resWidget.setHidden(true) // 显示目标组件
            })
          })
          hiddenRulesMultiple?.some((item) => {
            if (item.widgetNames.length) {
              const flag = item.optionValues.sort().join() === currentValue.sort().join()
              item.widgetNames.map((widgetName) => {
                const resWidget = this.getWidgetRef(widgetName)
                resWidget.setHidden(!flag) // 显示目标组件
              })
              if (flag) return true
            }
          })
        } else {
          // 每次单击的时候把optionItems里面相关的先全部隐藏掉再执行
          const optionItems = this.field.options.optionItems
          optionItems.map((option) => {
            option.widgetNames?.map((widgetName) => {
              const resWidget = this.getWidgetRef(widgetName)
              resWidget.setHidden(true)
            })
          })
          optionItems.some((option) => {
            option.widgetNames?.map((widgetName) => {
              const resWidget = this.getWidgetRef(widgetName)
              resWidget.setHidden(!(currentValue === option.value.toString())) // 显示目标组件
            })
            if (currentValue === option.value.toString()) return true
          })
        }
      }
    },

    // 数据联动第三步
    /**
     * @description: 关联组件触发
     * 注意多选比对时需 sort().join()
     * 从 change 的组件中的 linkageWidgetNames 拿到目标组件中的 linkage 进行处理 拿 axios 比对匹配值 对目标组件进行赋值
     */
    async handleDataLinkage() {
      const subformRowId = this.subFormItemFlag ? "@row" + this.subFormRowId : ""
      const linkageWidgetNames = this.field.options.linkageWidgetNames // 数据联动的组件
      const currentWidget = this.getWidgetRef(this.field.options.name + subformRowId) // 当前组件 非被联动的组件 而是用于触发联动
      const currentValue = currentWidget?.getValue() // 当前组件的值
      // 联动涉及的组件为空或当前组件值为空直接结束当前 mounted
      if ((!linkageWidgetNames?.length || !currentValue) && this.field.type !== "switch") return
      const allForms = []
      linkageWidgetNames.map((widgetName) => {
        const linkageWidget = this.getWidgetRef(widgetName + subformRowId) // 获取规则组件
        if (!linkageWidget) return
        const linkage = linkageWidget.field.options.linkage
        if (!linkage) return
        // 涉及的其中一个组件被删除时，不再做数据联动
        const flag = linkage.rules.every((item) => {
          const widgetValue = this.getWidgetRef(item.currentWidgetField + subformRowId)
          return widgetValue
        })
        // 涉及的其中一个组件没数据时，不做数据联动
        const isWidgetHasData = linkage.rules.every((item) => {
          const widgetValue = this.getWidgetRef(item.currentWidgetField + subformRowId)
          const val = widgetValue.getValue()
          return (Array.isArray(val) ? val.length : val) || widgetValue.field.type === "switch"
        })
        // 做删除 linkageWidgetNames 处理，后续 change 也不在触发
        if (!flag) {
          linkage.rules.map((item) => {
            const widgetValue = this.getWidgetRef(item.currentWidgetField + subformRowId)
            if (widgetValue) {
              const delLinkages = widgetValue.field.options.linkageWidgetNames
              const delIndex = delLinkages.findIndex(linkage.currentWidgetName)
              delLinkages.splice(delIndex, 1)
              widgetValue.setWidgetOption("linkageWidgetNames", delLinkages)
            }
          })
        }

        // 组件被删除或联动规则为空直接跳出本次循环 开始下一次循环
        if (!flag || !isWidgetHasData) return
        // linkage.appId // 应用 id
        // linkage.formId // 表单 id
        allForms.push(linkage)
      })
      // 在此处调接口
      const requerts = allForms.map((f) => {
        const rules = f.rules.map((r) => {
          const ruleWidget = this.getWidgetRef(r.currentWidgetField + subformRowId)
          const val = ruleWidget.getValue()
          return {
            conditionLogic: 0,
            fieldId: r.selectedWidgetField,
            operator: 0,
            fieldData: {
              fieldType: r.type,
              Value: Array.isArray(val) ? val.sort((a, b) => a - b) : val,
            },
          }
        })
        const params = {
          dataSetOrPageId: f.formId,
          fields: [f.selectedWidgetName],
          rule: rules,
        }
        // this.$oaAjax.post(`/api/oa/dataset/fetchfirstdata`, params) 单挑获取接口
        return params
      })
      // const formData = await Promise.all(requerts) // 获取表单的结果集
      const formData = await this.$oaAjax.post(`/api/oa/dataset/fetchMutipleData`, requerts)
      formData.map((fData, i) => {
        const linkageWidget = this.getWidgetRef(allForms[i].currentWidgetName + subformRowId)
        console.log(linkageWidget)
        if (["radio", "select"].includes(linkageWidget.field.type) && typeof fData === "number") {
          fData = fData.toString()
        } else if (["user", "org"].includes(linkageWidget.field.type) && !linkageWidget.field.options.multiple) {
          fData = +fData
        }
        linkageWidget.setValue(fData) // 最终结果
      })
    },

    emitAppendButtonClick() {
      /* 必须调用mixins中的dispatch方法逐级向父组件发送消息！！ */
      this.dispatch("VFormRender", "appendButtonClick", [this])
    },

    handleOnChange(val, oldVal) {
      // 自定义onChange事件
      if (this.field.options.onChange) {
        let changeFn = new Function("value", "oldValue", this.field.options.onChange)
        changeFn.call(this, val, oldVal)
      }
    },

    handleOnChangeForSubForm(val, oldVal, subFormData, rowId) {
      // 子表单自定义onChange事件
      if (this.field.options.onChange) {
        let changeFn = new Function("value", "oldValue", "subFormData", "rowId", this.field.options.onChange)
        changeFn.call(this, val, oldVal, subFormData, rowId)
      }
    },

    handleButtonWidgetClick() {
      if (this.designState) {
        // 设计状态不触发点击事件
        return
      }

      if (this.field.options.onClick) {
        let changeFn = new Function(this.field.options.onClick)
        changeFn.call(this)
      } else {
        this.dispatch("VFormRender", "buttonClick", [this])
      }
    },

    remoteQuery(keyword) {
      if (this.field.options.onRemoteQuery) {
        // 编写了远程搜索函数
        let remoteFn = new Function("keyword", this.field.options.onRemoteQuery)
        remoteFn.call(this, keyword)
      } else if (this.field.options.remote && this.field.options.optionType) {
        // 未编写远程搜索函数，但是开启了远程搜索
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.field.options.optionType === 1) {
            this.useDataSourceForOptionItems(keyword)
          } else {
            this.useFormDataForOptionItems(keyword)
          }
          clearTimeout(this.timer)
        }, 500)
      }
    },

    // --------------------- 事件处理 end ------------------//

    // --------------------- 以下为组件支持外部调用的API方法 begin ------------------//
    /* 提示：用户可自行扩充这些方法！！！ */

    getFormRef() {
      /* 获取VFrom引用，必须在VForm组件created之后方可调用 */
      return this.refList.v_form_ref
    },

    getWidgetRef(widgetName, showError) {
      let foundRef = this.refList[widgetName]
      if (!foundRef && !!showError) {
        this.$message.error(this.i18nt("render.hint.refNotFound") + widgetName)
      }
      return foundRef
    },

    getFieldEditor() {
      // 获取内置的el表单组件
      return this.$refs.fieldEditor
    },

    /*
      注意：VFormRender的setFormData方法不会触发子表单内field-widget的setValue方法，
      因为setFormData方法调用后，子表单内所有field-widget组件已被清空，接收不到setFormData事件！！
    * */
    setValue(newValue) {
      /* if ((this.field.type === 'picture-upload') || (this.field.type === 'file-upload')) {
        this.fileList = newValue
      } else */ if (this.field.formItemFlag) {
        let oldValue = deepClone(this.fieldModel)
        this.fieldModel = newValue
        this.initFileList()

        this.syncUpdateFormModel(newValue)
        this.emitFieldDataChange(newValue, oldValue)
      }
    },

    getValue() {
      /* if ((this.field.type === 'picture-upload') || (this.field.type === 'file-upload')) {
        return this.fileList
      } else */ {
        return this.fieldModel
      }
    },

    resetField() {
      if (this.subFormItemFlag) {
        // 跳过子表单组件
        return
      }

      let defaultValue = this.field.options.defaultValue
      this.setValue(defaultValue)

      // 清空上传组件文件列表
      if (this.field.type === "picture-upload" || this.field.type === "file-upload") {
        this.$refs.fieldEditor.clearFiles()
        this.fileList.splice(0, this.fileList.length)
      }
    },

    setWidgetOption(optionName, optionValue) {
      // 通用组件选项修改API
      if (Object.prototype.hasOwnProperty.call(this.field.options, optionName)) {
        this.field.options[optionName] = optionValue
        // TODO: 是否重新构建组件？？有些属性修改后必须重新构建组件才能生效，比如字段校验规则。
      }
    },

    setReadonly(flag) {
      this.field.options.readonly = flag
    },

    setDisabled(flag) {
      this.field.options.disabled = flag
    },

    setAppendButtonVisible(flag) {
      this.field.options.appendButton = flag
    },

    setAppendButtonDisabled(flag) {
      this.field.options.appendButtonDisabled = flag
    },

    setHidden(flag) {
      this.field.options.hidden = flag

      if (flag) {
        // 清除组件校验规则
        this.clearFieldRules()
      } else {
        // 重建组件校验规则
        this.buildFieldRules()
      }
    },

    setRequired(flag) {
      this.field.options.required = flag
      this.buildFieldRules()
    },

    setLabel(newLabel) {
      this.field.options.label = newLabel
    },

    focus() {
      if (!!this.getFieldEditor() && !!this.getFieldEditor().focus) {
        this.getFieldEditor().focus()
      }
    },

    clearSelectedOptions() {
      // 清空已选选项
      if (!["checkbox", "radio", "select", "multiselect"].includes(this.field.type)) return

      if (this.field.type === "checkbox" || this.field.type === "multiselect") {
        this.fieldModel = []
      } else {
        this.fieldModel = ""
      }
    },

    /**
     * 加载选项，并清空字段值
     * @param options
     */
    loadOptions(options) {
      /*
      this.field.options.optionItems = deepClone(options)
      //this.clearSelectedOptions()  //清空已选选项
       */

      this.field.options.optionItems = translateOptionItems(
        options,
        this.field.type,
        this.field.options.labelKey,
        this.field.options.valueKey,
        this.field.options.auxiliaryTextKey
      )
    },

    /**
     * 重新加载选项，不清空字段值
     * @param options
     */
    reloadOptions(options) {
      // this.field.options.optionItems = deepClone(options)

      this.field.options.optionItems = translateOptionItems(
        options,
        this.field.type,
        this.field.options.labelKey,
        this.field.options.valueKey,
        this.field.options.auxiliaryTextKey
      )
    },

    /**
     * 返回radio/checkbox/select/cascader的选项数据
     * @returns 选择项数组
     */
    getOptions() {
      return this.field.options.optionItems
    },

    disableOption(optionValue) {
      this.disableOptionOfList(this.field.options.optionItems, optionValue)
    },

    enableOption(optionValue) {
      this.enableOptionOfList(this.field.options.optionItems, optionValue)
    },

    /**
     * 返回选择项
     * @returns {*}
     */
    getOptionItems() {
      return this.field.options.optionItems
    },

    setUploadHeader(name, value) {
      this.$set(this.uploadHeaders, name, value)
    },

    setUploadData(name, value) {
      this.$set(this.uploadData, name, value)
    },

    setToolbar(customToolbar) {
      this.customToolbar = customToolbar
    },

    /**
     * 是否子表单内嵌的组件
     * @returns {boolean}
     */
    isSubFormItem() {
      return this.parentWidget ? this.parentWidget.type === "sub-form" : false
    },

    /**
     * 设置或取消设置字段只读查看模式
     * @param readonlyFlag
     */
    setReadMode(readonlyFlag = true) {
      this.formConfig.disabled = readonlyFlag
      this.fieldReadonlyFlag = readonlyFlag
    },

    // --------------------- 以上为组件支持外部调用的API方法 end ------------------//
  },
}
